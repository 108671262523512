/* eslint-disable no-undef */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Loader } from '@umahealth/occipital-ui/dist/compat'
import Camera from '../devices/video-camera.jsx'
import axios from 'axios'

const Video = ({ prototype }) => {
	const navigate = useNavigate()
	const [camera, setCamera] = useState(true)
	const [loading, setLoading] = useState(false)

	const cloudFunctionUrl = process.env.REACT_APP_CLOUD_FUNCTION_URL

	async function uploadToStorage(file) {
		const { data: path } = await axios({
			method: 'post',
			url: cloudFunctionUrl,
			headers: {
				'content-type': 'multipart/form-data',
			},
			params: {
				model: "heart_rate",
			},
			data: file,
		})
    	return path;
	}

	async function postVideoRegister(filePath) {
		try {
			const urlTest = await axios.get(prototype.url)
			// console.log("postVideoRegister:urlTest", urlTest)
			// if(urlTest.code === 405) console.log("status esperado de API correcta")
			// if(`${urlTest}sss` === 404) console.log("status esperado de API erronea")

			const headers = {
				'content-type': 'application/json',
				'x-api-key': process.env.REACT_APP_API_KEY_VIDEO,
				// 'Authorization': `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`
			}
			const bodyRequest = {
				"blob_path": filePath,
				"bucket_name": process.env.REACT_APP_BUCKET_NAME_VIDEO
			}
			
			const modelResult = await axios.post(prototype.url, bodyRequest, { headers })
			
			console.log("postVideoRegister:modelResult", modelResult)
		} catch (err) {
			throw new Error(JSON.stringify(err))
		}
	}

	const handleModelSubmit = async (file) => {
		try {
			setLoading(true)
			const filePath = await uploadToStorage(file)
			await postVideoRegister(filePath)
		} catch (err) {
			console.log(err)
			setCamera(false)
		} finally {
			setLoading(false)
		}
		// navigate('/output/model')
	}

	return (
		<>
			{loading && <Loader />}
			<div className='camera__test'>
				{camera &&
					<>
						<Camera
							prototype={prototype}
							onFinish={(file) => handleModelSubmit(file)}
						/>
					</>
				}
			</div>
		</>
	)
}

export default Video
