import React from 'react'
import { Button, Column, Header, Spacer } from '@umahealth/occipital-ui/dist/compat'
import { useNavigate } from 'react-router-dom'

export default function Output() {
  const navigate = useNavigate()
  return (
    <>
      <Header type='service' text='Output' />
      <Column alignment='center'>
        <Spacer value='40px' direction='vertical' />
        <pre>Output</pre>
        <Spacer value='40px' direction='vertical' />
        <Button action={() => navigate(`/`)}>
          Volver
        </Button>
      </Column>
    </>
  )
}
