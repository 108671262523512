/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import { useParams } from "react-router-dom";
import { doc, getDoc } from 'firebase/firestore'
import { Header, Paragraph, Spacer } from '@umahealth/occipital-ui/dist/compat'
import { db } from '../../config/firebase'
import Photo from '../inputs/photo';
import Video from '../inputs/video';

export default function CurrentModels() {
    const {model} = useParams()
    const [currentModel, setCurrentModel] = useState({})

    useEffect(() => {
        queryPrueba()
    }, [])

    const queryPrueba = async () => {
        try { // /prototypes/cardiovascular
            const currentModelRef = doc(db, `prototypes/${model}`)
            const currentModelSnapshot = await getDoc(currentModelRef)
            if(currentModelSnapshot.exists()) {
                setCurrentModel(currentModelSnapshot.data())
            }
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    const renderInput = () => {
        switch(currentModel.type) {
            case 'photo':
                return <Photo prototype={currentModel}/>
            case 'video':
                return <Video prototype={currentModel}/>
            default:
                return <>
                    <Spacer />
                    <Paragraph align='center'>No se detectó input</Paragraph>
                </>
        }
    }
  return (
    <div>
        <Header type='service' text={model?.toUpperCase()} />

        {renderInput()}
    </div>
  )
}
