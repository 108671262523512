import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom"
import './index.css'
import App from './App'
import CurrentModel from './components/views/currentModel'
import LoginWithEmail from './components/views/login'
import Output from './components/views/output'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "/model/:model",
    element: <CurrentModel />
  },
  {
    path: "/output/:model",
    element: <Output />
  },
  {
    path: "/login",
    element: <LoginWithEmail />
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <RouterProvider router={router} />
  </React.StrictMode>
);
