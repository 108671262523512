import React, { useCallback, useEffect, useRef } from 'react'
import CountDownComponent from './Timer'
import { useReactMediaRecorder } from './ReactMediaEncoder.tsx'
import styles from './camera.module.css'
import { useNavigate } from 'react-router-dom'

export default function Camera({ prototype, onFinish }) {
	const navigate = useNavigate()
	const videoDuration = prototype.dur ? prototype.dur / 1000 : 10
	const { status, startRecording, stopRecording, mediaBlob } =
		useReactMediaRecorder({ video: true });
	const camView = useRef()

	useEffect(() => {
		let track;
		(async function startCamera() {
			try {
				const constraints = {
					audio: false,
					video: { facingMode: 'user' },
					frameRate: prototype.fps ? prototype.fps : 30
				}
				const stream = await navigator.mediaDevices.getUserMedia(constraints)
				track = stream.getTracks()[0]
				if (camView?.current) {
					camView.current.srcObject = stream
					camView.current.onloadedmetadata = () => camView?.current?.play()
				}
			} catch (error) {
				console.error(error)
			}
		})()
		return () => {
			try {
				track?.stop()
			} catch (error) { console.error(error) }
		}
	}, [])

	const recordVideo = useCallback(() => {
		startRecording()
		setTimeout(async () => {
			stopRecording()
		}, videoDuration * 1000)
	}, [startRecording, stopRecording])


	useEffect(() => {
		if (status === 'stopped') {
			onFinish(mediaBlob)
			navigate('/output/model')
		}
	}, [status])

	return (
		<main className={styles.camera}>
			<video
				autoPlay
				className={styles.cameraSensorFlipped}
				playsInline
				ref={camView}
			/>
			<div className={styles.countDown}>
				<svg>
					<defs>
						<linearGradient id="gradient" x1="1" y1="0" x2="0" y2="0">
							<stop offset="0%" stopColor="#343d7e" />
							<stop offset="100%" stopColor="#343d7e" />
						</linearGradient>
					</defs>
				</svg>
				{status === 'recording' && <CountDownComponent videoTime={videoDuration} />}
			</div>
			<div className={styles.cameraTrigger}>
				<button
					className={styles.cameraTriggerBtn}
					onClick={() => recordVideo()}>
					{status === 'recording' ? 'Capturando...' : 'Capturar'}
				</button>
			</div>
		</main>
	)
}
